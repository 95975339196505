import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from 'prop-types'
import Gallery from '../../../plugins/gallery'

const GalleryLinear = ({ idxSection, language }) => {
    const data = useStaticQuery(graphql`
    query {
        allFile(filter: {extension: {regex: "/(jpg)|(png)|(gif)/"}, relativeDirectory: {eq: "stations/otsuki/linear"}}) {
            edges {
                node {
                    childImageSharp {
                        fluid(maxHeight: 1000, maxWidth: 1000, srcSetBreakpoints: [576, 768, 992, 1200]) {
                            src
                            aspectRatio
                            ...GatsbyImageSharpFluid
                        }
                    }
                    base
                }
            }
        }
      stationsJson(pages: {zh_hans: {sections: {elemMatch: {gallery: {directory: {eq: "stations/otsuki/linear"}}}}}}) {
        pages {
          zh_hans {
            sections {
              gallery {
                directory
                items {
                  fileName
                  title
                }
              }
            }
          }
        }
      }
    }
  `)
    let edges = data.allFile.edges;
    edges.sort(function(a,b) {
        if (a.node.base > b.node.base) {
            return 1;
        } else {
            return -1;
        }
    });
    const images = edges.map(edge => edge.node.childImageSharp.fluid.src);
    const thumbs = edges.map(edge => edge.node.childImageSharp.fluid);
    const captions = data.stationsJson.pages[language].sections[idxSection].gallery.items.map(item => item.title);

    return (
        <Gallery images={images} thumbs={thumbs} captions={captions} />
    )
}

GalleryLinear.propTypes = {
    idxSection: PropTypes.number,
    language: PropTypes.string,
}

export default GalleryLinear